import request from './request'
// TODO 获取验证码
function getRecruitRules(data) {
    return request('get','/qxapi/edu/baoming/getrecruitrules', data)
}

function getRecruitRuleTitle(data) {
    return request('get','/qxapi/edu/baoming/getrecruitruletitle', data)
}

// TODO 获取发票信息
function getTaxdep(data) {
    return request('get','/qxapi/edu/baoming/gettaxdep', data)
}

// TODO 获取发票信息
function updateTaxDep(data) {
    return request('post','/qxapi/edu/baoming/updatetaxdep', data)
}

// TODO 删除发票信息
function deleteTaxDep(data) {
    return request('delete','/qxapi/edu/baoming/deletetaxdep/' + data)
}

// TODO 选择报读时段
function selectTerm(data) {
    return request('get','/qxapi/edu/baoming/selectterm', data)
}

// TODO 判断是否只允许报读旧班
function checkIsOnlyOldClass(data) {
    return request('get','/qxapi/edu/baoming/checkisonlyoldclass', data)
}

// TODO 获取系别专业
function getDepts(data) {
    return request("get", "/qxapi/edu/baoming/getdepts", data)
}

// TODO 获取报读课程
function getBaoduCourses(data) {
    return request("get", "/qxapi/edu/baoming/getbaoducourses", data)
}

// TODO 获取少儿社团 报读课程
function getGroupBaoduCourses(data) {
    return request("get", "/qxapi/edu/baoming/getgroupbaoducourses", data)
}

// TODO 检测课程是否冲突、重复
function checkCourseForCheckItem(data) {
    return request("post", "/qxapi/edu/baoming/checkcourseforcheckitem", data)
}

// TODO 提交报读课程
function submitBaodu(data) {
    return request("post", "/qxapi/edu/baoming/submitbaodu", data)
}

// TODO 获取待支付课程
function getConfirmCourses(data) {
    return request("get", "/qxapi/edu/baoming/getconfirmcourses", data)
}

// TODO 取消课程
function cancelCourse(data) {
    return request("get", "/qxapi/edu/baoming/cancelcourse", data)
}

// TODO 取消已经发起支付的单
function cancelCourseForPay(data) {
    return request("get", "/qxapi/edu/baoming/cancelcourseforpay", data)
}

// TODO 发起支付
function toPay(data) {
    return request("post", "/qxapi/edu/baoming/topay", data)
}

// TODO 获取已报读课程
function getAlreadyBaoduClasses(data) {
    return request("get", "/qxapi/edu/baoming/getalreadybaoduclasses", data)
}

// TODO 申请退款
function refund(data) {
    return request("post", "/qxapi/edu/baoming/refund", data)
}

// TODO 使用优惠券
function getCoupon(data) {
    return request("get", "/qxapi/edu/coupon/coupon", data)
}

// TODO 获取退款查询
function getRefundClasses(data) {
    return request("get", "/qxapi/edu/baoming/refundclasses", data)
}

// TODO H5微信签名验证 wx.config用
function createJsapiSignature(data) {
    return request("get", "/qxapi/edu/wechat/createjsapisignature", data)
}

// TODO 根据ClassId获取班级
function getClassById(data) {
    return request("get", "/qxapi/edu/classsetup/classsetups/" + data)
}


// TODO 获取所有班级
function getClassSetups(data) {
    return request("get", "/qxapi/edu/classsetup/classsetups", data)
}

// TODO 获取报名情况
function getBaoMingQingKuang(data) {
    return request("get", "/qxapi/edu/regclass/baomingqingkuang/" , data)
}

// TODO 获取所有学期
function getTermsetups(data) {
    return request('get','/qxapi/edu/termsetup/termsetups', data)
}


// TODO 获取教学学期
function getJiaoXueTermSetup(data) {
    return request("get", "/qxapi/edu/termsetup/jiaoxuetermsetup", data)
}

// TODO 提交停课通知
function submitTktz(data) {
    return request("post", "/qxapi/edu/wxmsgmaster/submittktz" , data)
}

// TODO 后去教师停课通知
function getWxMsgMasterByCustId(data) {
    return request("get", "/qxapi/edu/wxmsgmaster/wxmsgmasterbycustid", data)
}

function getTktzById(data) {
    return request("get", "/qxapi/edu/wxmsgmaster/tktzbyid", data)
}

function sendTktzMsg(data) {
    return request("post", "/qxapi/edu/wxmsgmaster/sendtktzmsg" , data)
}

function getMsgBak(data) {
    return request("get", "/qxapi/edu/wxmsgmaster/msgbaks", data)
}

export default {
    getRecruitRules,
    getRecruitRuleTitle,
    getTaxdep,
    updateTaxDep,
    deleteTaxDep,
    selectTerm,
    checkIsOnlyOldClass,
    getDepts,
    getBaoduCourses,
    getGroupBaoduCourses,
    submitBaodu,
    getConfirmCourses,
    cancelCourse,
    cancelCourseForPay,
    toPay,
    getAlreadyBaoduClasses,
    refund,
    getCoupon,
    checkCourseForCheckItem,
    getRefundClasses,
    createJsapiSignature,
    getClassById,
    getBaoMingQingKuang,
    getTermsetups,
    getJiaoXueTermSetup,
    getClassSetups,
    submitTktz,
    getWxMsgMasterByCustId,
    getTktzById,
    sendTktzMsg,
    getMsgBak
}
