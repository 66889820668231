<template>
    <div class="edu-frame">
        <edu-nav-bar title="请假申请" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
			<div  v-for="(leave, index) in leaves" :key="index" class="edu-group class-item">
			<div class="class-detail" @click="toupd(leave.leaveApplyId)">
				<van-row>
				  <van-col span="8">申请人:{{leave.applyUserName}}</van-col>
				  <van-col span="16">状态:{{leave.fstatusStr}}</van-col>
				</van-row>
				<van-row>
				  <van-col span="24">请假时间:{{leave.leaveBeginTime}}~{{leave.leaveEndTime}}</van-col>
				</van-row>
				<van-row>
				  <van-col span="8">审核人:{{leave.checkUserName}}</van-col>
				  <van-col span="16">审核时间:{{leave.checkTime}}</van-col>
				</van-row>
			</div>
        </div>
		</div>
		<div class="bottom-frame">
			<van-button square block type="primary" @click="toadd" :loading="submitting">新增请假</van-button>
		    <!-- <van-button square block type="warning" @click="save()" :loading="submitting">保存</van-button> -->
		</div>
		
    </div>
</template>
<script>
    import {Button, Tag, Checkbox, Row, Col} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from '@/api/EduConstants';
    import XEUtils from 'xe-utils';
	import LeaveApi from "@/api/LeaveApi";

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
            VanTag: Tag,
            VanCheckbox: Checkbox,
			VanRow: Row,
			VanCol: Col,
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
				show: false,
				submitting: false,
				cust: Tools.getCurCust(),
				leaves:[],
            }
        },
        methods: {
			list(){
				this.loading = true;
				let params = {};
				params.applyUserId = this.cust.custId;
				LeaveApi.list(params).then(response => {
				    this.loading = false
				    this.leaves = response.res;
				}).catch(()=>{
				    this.loading = false
				})
			},
			toadd(){
				this.$router.push({name: 'szqj', query: {new_upd_title:'新增请假'}})
			},
			toupd(leaveApplyId){
				this.$router.push({name: 'szqj', query: {new_upd_title:'修改请假',leaveApplyId:leaveApplyId}})
			}
        },
        mounted() {
			this.list();
        }
    }
</script>
<style scoped>
</style>
