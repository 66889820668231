import request from './request'

function list(data) {
    return request('get','/qxapi/edu/leaveapply/list', data)
}

function getById(data) {
    return request('get','/qxapi/edu/leaveapply/get/' + data)
}

function submit(data) {
    return request('post','/qxapi/edu/leaveapply/submit', data)
}

export default {
    list,
	getById,
	submit
}
